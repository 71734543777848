import {
  DrawingUtils,
  FilesetResolver,
  NormalizedLandmark,
  PoseLandmarker,
  PoseLandmarkerResult,
} from '@mediapipe/tasks-vision';

const demosSection = document.getElementById('demos');

let poseLandmarker: PoseLandmarker;
let webcamRunning: Boolean = false;
let runningMode: 'IMAGE' | 'VIDEO' = 'IMAGE';

const enableWebcamButton = document.getElementById('webcamButton')!;
const videoHeight = 360;
const videoWidth = 480;

// Before we can use PoseLandmarker class we must wait for it to finish
// loading. Machine Learning models can be large and take a moment to
// get everything needed to run.
const createPoseLandmarker = async () => {
  const vision = await FilesetResolver.forVisionTasks(
    'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm'
  );
  poseLandmarker = await PoseLandmarker.createFromOptions(vision, {
    baseOptions: {
      modelAssetPath: `https://storage.googleapis.com/mediapipe-models/pose_landmarker/pose_landmarker_lite/float16/1/pose_landmarker_lite.task`,
      delegate: 'GPU',
    },
    runningMode: 'VIDEO',
    numPoses: 2,
  });
};
createPoseLandmarker().then(() => {
  demosSection?.classList.remove('invisible');
});

const video = document.getElementById('webcam') as HTMLVideoElement;
const canvasElement = document.getElementById(
  'output_canvas'
) as HTMLCanvasElement;
const canvasCtx = canvasElement.getContext('2d')!;
const drawingUtils = new DrawingUtils(canvasCtx);

// Check if webcam access is supported.
const hasGetUserMedia = () => !!navigator.mediaDevices?.getUserMedia;

// If webcam supported, add event listener to button for when user
// wants to activate it.
if (hasGetUserMedia()) {
  enableWebcamButton.addEventListener('click', enableCam);
} else {
  console.warn('getUserMedia() is not supported by your browser');
}

// Enable the live webcam view and start detection.
function enableCam(event: any) {
  if (!poseLandmarker) {
    console.log('Wait! poseLandmaker not loaded yet.');
    return;
  }

  if (webcamRunning === true) {
    webcamRunning = false;
    enableWebcamButton.innerText = 'DETECT POSE';
  } else {
    webcamRunning = true;
    enableWebcamButton.innerText = 'STOP DETECTION';
  }

  // getUsermedia parameters.
  const constraints = {
    video: true,
  };

  // Activate the webcam stream.
  navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
    video.srcObject = stream;
    video.addEventListener('loadeddata', predictWebcam);
  });
}

let lastVideoTime = -1;
async function predictWebcam() {
  canvasElement.style.height = `${videoHeight}px`;
  canvasElement.style.width = `${videoWidth}px`;

  video.style.height = `${videoHeight}px`;
  video.style.width = `${videoWidth}px`;

  // Now let's start detecting the stream.
  if (runningMode === 'IMAGE') {
    runningMode = 'VIDEO';
    await poseLandmarker.setOptions({ runningMode: 'VIDEO' });
  }
  let startTimeMs = performance.now();
  if (lastVideoTime !== video.currentTime) {
    lastVideoTime = video.currentTime;
    poseLandmarker.detectForVideo(video, startTimeMs, (result) => {
      visualizePose(result);
      const framing = analyzeFraming(result, videoWidth, videoHeight);
      console.log('framing:', framing);

      const isFramed = isUserFullyFramed(result);
      console.log('isFramed:', isFramed);
    });
  }

  // Call this function again to keep predicting when the browser is ready.
  if (webcamRunning === true) {
    window.requestAnimationFrame(predictWebcam);
  }
}

function visualizePose(result: PoseLandmarkerResult) {
  canvasCtx.save();
  canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
  for (const landmark of result.landmarks) {
    drawingUtils.drawLandmarks(landmark, {
      radius: (data) => DrawingUtils.lerp(data.from!.z, -0.15, 0.1, 0.5, 1),
    });
    drawingUtils.drawConnectors(landmark, PoseLandmarker.POSE_CONNECTIONS);
  }
  canvasCtx.restore();
}

function analyzeFraming(
  result: PoseLandmarkerResult,
  videoWidth: number,
  videoHeight: number
): 'TOO_CLOSE' | 'TOO_FAR' | 'PERFECT' {
  const landmarks = result.landmarks[0];
  // You can adjust these threshold values based on your requirements
  const edgeThreshold = 0.05; // percentage close to the edge
  const centerThreshold = 0.25; // percentage close to the center

  const headPosition = landmarks[0]; // Assuming the first landmark corresponds to the head
  const footPosition = landmarks[landmarks.length - 1]; // Assuming the last landmark corresponds to the feet

  if (headPosition.y < edgeThreshold || footPosition.y > 1 - edgeThreshold) {
    return 'TOO_CLOSE';
  } else if (
    headPosition.y > centerThreshold &&
    footPosition.y < 1 - centerThreshold
  ) {
    return 'TOO_FAR';
  }
  return 'PERFECT';
}

function isUserFullyFramed(result: PoseLandmarkerResult): boolean {
  console.log('isUserFullyFramed -> result:', result);
  const edgeThreshold = 0.05; // 5% of the frame's height/width for leeway

  for (const landmark of result.landmarks[0] as NormalizedLandmark[]) {
    if (
      landmark.x < edgeThreshold ||
      landmark.x > 1 - edgeThreshold ||
      landmark.y < edgeThreshold ||
      landmark.y > 1 - edgeThreshold
    ) {
      console.log('isUserFullyFramed -> landmark.x:', landmark.x);
      // If any landmark is too close to the frame's edge, user might not be fully framed
      return false;
    }
  }
  return true;
}
